<template>
    <div class="version-detail">
        <md-card>
            <md-card-header>
                <h1>{{version.version}}</h1>
                <span>({{version.hash}})</span>
            </md-card-header>
            <md-card-header>
                <span>{{version.date | date}}</span>
            </md-card-header>
            <md-card-content v-html="version.description"></md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    props: ["id"],
    data() {
        return {
            version: {}
        };
    },
    watch: {
        id() {
            this.loadVersion();
        }
    },
    methods: {
        async loadVersion() {
            let result = await this.$store.dispatch("crud/get", {
                api: `versions/${this.id}`
            });
            this.version = result;
        }
    },
    mounted() {
        this.loadVersion();
    }
};
</script>
<style lang="less">
.version-detail {
    .md-card {
        max-width: 1000px;
        margin: auto;
        h1 {
            margin: 0;
            margin-right: 10px;
        }
        .md-card-header {
            display: flex;
            align-items: flex-end;
            span {
                font-size: 14px;
                color: #666;
            }
        }
    }
}
</style>

